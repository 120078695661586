<template>
  <div>
    <!-- <HeadCards /> -->
    <full-table
      :other-filter-props="{
        add: true,
        search: true,
        gender: true,
        role: true,
      }"
      :items="GET_USER_IN_GROUP.results"
      :fields="fields"
      :rows="GET_USER_IN_GROUP.count"
      :edit-btn="false"
      :first-params="params"
      :empty-text="$t('general.no_data_to_view')"
      @refresh="FETCH_USER_IN_GROUP"
      @row-clicked="(data) => $router.push(`/users/${data.user.id}/info`)"
      @detail-button-clicked="(id, item) => $router.push(`/users/${item.user.id}/info`)"
      @delete-button-clicked="modalDeleteUser"
      @add-button-clicked="addUser"
    />
    <add-users-to-tag-modal @refresh="refresh" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import toast from '@/mixins/toast'
import areYouSure from '@/mixins/areYouSure'
// import HeadCards from '@/components/HeadCards.vue'
import FullTable from '@/components/FullTable.vue'
import AddUsersToTagModal from './modals/AddUsersToTagModal.vue'

export default {
  name: 'User',
  components: {
    FullTable,
    AddUsersToTagModal,
    // HeadCards,
  },
  directives: {
    Ripple,
  },
  mixins: [toast, areYouSure],
  data() {
    return {
      isEdit: false,
      params: {
        page_size: 10,
        page: 1,
        tag: this.$route.params.id,
        ordering: null,
        search: null,
      },
      fields: [
        {
          label: 'ID',
          key: 'user.contact.id',
          sortable: true,
        },
        {
          label: 'users.full_name',
          key: 'user.contact.full_name',
          sortable: true,
        },
        {
          label: 'users.roles',
          key: 'users.roles',
        },
        {
          label: 'users.phone_number',
          key: 'user.phones',
        },
        {
          label: 'general.actions',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('knowledgeBase', ['GET_USER_IN_GROUP']),
  },
  mounted() {
    this.FETCH_ONE_GROUP(this.$route.params.id)
      .then(res => {
        this.updateBreadcrumb(res.data)
      })
  },
  methods: {
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    ...mapActions('knowledgeBase', ['FETCH_ONE_GROUP', 'FETCH_USER_IN_GROUP', 'DELETE_USER_IN_GROUP']),
    addUser() {
      this.$bvModal.show('UsersAddToTagModal')
    },
    refresh() {
      this.FETCH_USER_IN_GROUP(this.params)
    },
    updateBreadcrumb(data) {
      const [first, second] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      second.text = `${data.title}`
      this.UPDATE_BREADCRUMB([first, second])
    },
    modalDeleteUser(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_USER_IN_GROUP(id)
            .then(() => {
              this.$_successToast(this.$t('general.removed'))
              this.params.page = 1
              this.FETCH_USER_IN_GROUP(this.params)
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
  },
}
</script>
